import axios from "axios";
import FlatList from "flatlist-react";
import baseURL from "../Assets/common/baseURL";
import SortIcon from "@mui/icons-material/Sort";
import ProductCard from "../Components/Products/ProductCard";

import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Menu,
  Tabs,
  Alert,
  Button,
  useTheme,
  MenuItem,
  Container,
  Typography,
  useMediaQuery,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import API from "../utils/API";

function ShowSearchedProducts() {
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  let { searchQuery } = useParams();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [tabValue, setTabValue] = useState("product");
  const [brand, setBrand] = useState([]);
  const [next, setNext] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const [currentProducts, setCurrentProducts] = useState(0);

  useEffect(() => {
    setNext(null);
    setProducts([]);
    setTotalProducts(0);
    setCurrentProducts(0);
    loadBrands();
    if (searchQuery.length >= 3) {
      handleSearch(searchQuery);
    }

    // return setProducts([]);
  }, [searchQuery, tabValue]);

  const loadBrands = async () => {
    const res = await API({
      method: "GET",
      url: "brand",
    });
    setBrand(res);
  };

  const getURL = () => {
    switch (tabValue) {
      case "product":
        return `product_search?search=${searchQuery}`;
      case "generic_name":
        return `product?generic_name=${searchQuery}`;
      case "brand":
        return `product?brand=${
          brand.find((b) => b.name.toLowerCase() === searchQuery.toLowerCase())
            .id
        }`;
      default:
        return `product?brand=${searchQuery}`;
    }
  };

  const handleSearch = async (_query) => {
    setLoading(true);
    try {
      setProducts([]);
      const url = getURL();
      if (_query.length <= 3) return;
      const res = await API({
        method: "GET",
        url,
      });
      setProducts(res.results);
      setTotalProducts(res.count);
      setCurrentProducts(res.results.length);
      setNext(res.next);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async () => {
    setLoading(true);
    const res = await API({
      method: "GET",
      url: next,
    });
    setProducts([...products, ...res.results]);
    setCurrentProducts(currentProducts + res.results.length);
    setNext(res.next);
    setLoading(false);
  };

  const renderProduct = (item) => {
    return (
      <Box
        sx={{
          height: "100%",
          width: "20vw",
        }}
      >
        <ProductCard product={item} responsive={true} />
      </Box>
    );
  };

  const handleChange = (event, newValue) => setTabValue(newValue);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        height="50vh"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth={"xl"} sx={{ mt: 2 }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            id="sort-menu-button"
            aria-controls={open ? "sort-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Typography fontSize={16} fontWeight={"bold"} sx={{ mr: 1 }}>
              Sort By:{" "}
            </Typography>
            <SortIcon fontSize="large" />
          </Button>
          <Menu
            id="sort-menu"
            aria-labelledby="sort-menu-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setProducts([...products].sort((a, b) => a.price - b.price));
              }}
            >
              Price: Low to High
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setProducts([...products].sort((a, b) => b.price - a.price));
              }}
            >
              Price: High to Low
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Tabs value={tabValue} onChange={handleChange}>
        <Tab value={"product"} label={"Product"} />
        <Tab value={"generic_name"} label={"Generic Name"} />
        <Tab value={"brand"} label={"Brand"} />
      </Tabs>
      <Box>
        {/* <FlatList
          list={products}
          renderItem={renderProduct}
          renderWhenEmpty={() => (
            <div
              style={{
                marginTop: 20,
                minHeight: "50vh",
              }}
            >
              <Alert severity="warning">
                <Typography fontWeight={"bold"} fontSize={16}>
                  No Products Found
                </Typography>
              </Alert>
            </div>
          )}
          renderOnScroll
          search={{
            by: tabValue,
            term: searchQuery,
            caseInsensitive: true,
          }}
          display={{
            grid: true,
            gridGap: isMatch ? "10px" : "100px",
          }}
        /> */}
        <Grid container spacing={2} my={2}>
          {products.length === 0 ? (
            <Alert severity="warning">
              <Typography fontWeight={"bold"} fontSize={16}>
                No Products Found
              </Typography>
            </Alert>
          ) : (
            products.map((product) => (
              <Grid item xs={12} md={4} lg={3}>
                <Box
                  sx={{
                    height: "100%",
                    // width: "20vw",
                  }}
                >
                  <ProductCard
                    product={product}
                    responsive={true}
                    breadcrumb={searchQuery}
                  />
                </Box>
              </Grid>
            ))
          )}
        </Grid>
        {next && !loading && (
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography>
              You've viewed {currentProducts} of {totalProducts}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{ height: 50, mt: 2, mb: 5 }}
              onClick={loadMore}
            >
              Load More Products
            </Button>
          </Stack>
        )}
      </Box>
    </Container>
  );
}

export default ShowSearchedProducts;
