import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Typography,
  Stack,
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";

import LoaderImage from "../../Assets/images/loader.gif";

import FlatList from "flatlist-react";

import API from "../../utils/API";
import ProductCard from "../../Components/Products/ProductCard";

function ShowProducts() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const [currentProducts, setCurrentProducts] = useState(20);

  const [tabValue, setTabValue] = useState("A");

  useEffect(() => {
    loadProduct();
  }, [tabValue]);

  const loadProduct = async () => {
    setLoading(true);
    try {
      const res = await API({
        method: "GET",
        url: `/product_search/?search=${tabValue}`,
      });
      setProducts(res.results);
      setNext(res.next);
      setTotalProducts(res.count);
    } catch {
      console.log("Error");
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async () => {
    setLoading(true);
    try {
      const res = await API({
        method: "GET",
        url: next,
      });
      setProducts([...products, ...res.results]);
      setNext(res.next);
      setCurrentProducts(products.length + res.results.length);
    } catch {
      console.log("Error");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (item) => {
    setTotalProducts(0);
    setCurrentProducts(20);
    setNext(null);
    setProducts([]);
    setTabValue(item);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <Container maxWidth={"xl"} sx={{ mt: 2 }}>
      <Breadcrumbs>
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link underline="hover" color="inherit">
          A to Z
        </Link>
      </Breadcrumbs>
      <Stack spacing={2}>
        <Box>
          <Typography fontSize={22} fontWeight={"bold"}>
            Medicines Starting with {tabValue}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{
            p: 1,
            pl: 2,
            pr: 2,
            backgroundColor: "#2c3c93",
            borderRadius: 1,
          }}
        >
          {alphabets.map((item) => {
            return (
              <Link
                onClick={() => handleChange(item)}
                sx={{ cursor: "pointer" }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: item === tabValue ? "800" : "normal",
                    backgroundColor: item === tabValue ? "secondary.main" : "transparent",
                    py: 0.5,
                    px: 1.5,
                    borderRadius: 2.5,
                    textDecorationLine:
                      item === tabValue ? "underline" : "none",
                  }}
                >
                  {item}
                </Typography>
              </Link>
            );
          })}
        </Box>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          id="sort-menu-button"
          aria-controls={open ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Typography fontSize={16} fontWeight={"bold"} sx={{ mr: 1 }}>
            Sort By:{" "}
          </Typography>
          <SortIcon fontSize="large" />
        </Button>
        <Menu
          id="sort-menu"
          aria-labelledby="sort-menu-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setProducts(
                [...products].sort(
                  (a, b) => parseFloat(a.price_unit) - parseFloat(b.price_unit)
                )
              );
            }}
          >
            Price: Low to High
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setProducts(
                [...products].sort(
                  (a, b) => parseFloat(b.price_unit) - parseFloat(a.price_unit)
                )
              );
            }}
          >
            Price: High to Low
          </MenuItem>
        </Menu>
      </Box>
      <Box
        sx={{
          mt: 4,
        }}
      >
        {/* <FlatList
          list={products}
          renderItem={renderProduct}
          renderWhenEmpty={() => (
            <div
              style={{
                minHeight: "30vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={LoaderImage} height={500} />
            </div>
          )}
          renderOnScroll
          display={{
            grid: true,
            minColumnWidth: "100px",
            gridGap: "80px",
          }}
        /> */}
        <Grid container spacing={1}>
          {/* <Grid item xs={12} md={4} lg={4}>
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  <Typography fontWeight={"bold"} variant="h5">
                    Sort By
                  </Typography>
                  <Link
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAnchorEl(null);
                      setProducts(
                        [...products].sort(
                          (a, b) =>
                            parseFloat(a.price_unit) - parseFloat(b.price_unit)
                        )
                      );
                    }}
                  >
                    Low to High
                  </Link>
                  <Link
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAnchorEl(null);
                      setProducts(
                        [...products].sort(
                          (a, b) =>
                            parseFloat(b.price_unit) - parseFloat(a.price_unit)
                        )
                      );
                    }}
                  >
                    High to Low
                  </Link>
                </Stack>
              </CardContent>
            </Card>
          </Grid> */}
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={0}>
              {products.map((item) => (
                <Grid item xs={12} md={3} lg={2.4}>
                  <ProductCard product={item} breadcrumb={tabValue} />
                </Grid>
              ))}
            </Grid>
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Box
                  component={"img"}
                  src={require("../../Assets/images/loader.gif")}
                  height={100}
                  width={300}
                  style={{
                    objectFit: "cover",
                  }}
                  sx={{
                    mt: 2,
                    mb: 5,
                  }}
                  alt={"loading"}
                />
              </Box>
            )}

            {next && !loading && (
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Typography>
                  You've viewed {currentProducts} of {totalProducts}
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{ height: 50, mt: 2, mb: 5 }}
                  onClick={loadMore}
                >
                  Load More Products
                </Button>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default ShowProducts;
