import axios from "axios";
import FlatList from "flatlist-react";
import SortIcon from "@mui/icons-material/Sort";
import baseURL from "../../Assets/common/baseURL";
import LoaderImage from "../../Assets/images/loader.gif";
import ProductCard from "../../Components/Products/ProductCard";

import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Link,
  Menu,
  Button,
  MenuItem,
  useTheme,
  Container,
  Typography,
  Breadcrumbs,
  useMediaQuery,
  Grid,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import API from "../../utils/API";

function SubCatProducts() {
  const theme = useTheme();
  let { subCatID } = useParams();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subCategory, setSubCategory] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [next, setNext] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const [currentProducts, setCurrentProducts] = useState(0);
  const [selectedSort, setSelectedSort] = useState(null);

  useEffect(() => {
    setLoading(false);
    setCurrentProducts(0);
    setTotalProducts(0);
    loadCategories();
    fetchProducts();
  }, [subCatID]);

  const loadCategories = async () => {
    const res = await API({
      method: "GET",
      url: "subcategory/",
    });
    setSubCategory(res.filter((item) => item.id === parseInt(subCatID))[0]);
    setSubCategories(res.filter((item) => item.name !== "A to Z"));
  };

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    const res = await API({
      method: "GET",
      url: `product/?child_category=${subCatID}`,
    });
    setProducts(res.results);
    setNext(res.next);
    setTotalProducts(res.count);
    setLoading(false);
    setCurrentProducts(res.results.length);
  }, [subCatID]);

  const loadMore = async () => {
    setLoading(true);
    try {
      const res = await API({
        method: "GET",
        url: next,
      });
      setProducts([...products, ...res.results]);
      setNext(res.next);
      setCurrentProducts(products.length + res.results.length);
    } catch {
      console.log("Error");
    }
    setLoading(false);
  };

  //Sort Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Container maxWidth={"xl"} sx={{ mt: 2 }}>
        <Breadcrumbs
          sx={{
            mb: 2,
          }}
        >
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Link underline="hover" color="inherit">
            {subCategory?.name}
          </Link>
        </Breadcrumbs>
        {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            id="sort-menu-button"
            aria-controls={open ? "sort-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Typography fontSize={18} sx={{ mr: 1 }}>
              Sort By:{" "}
            </Typography>
            <SortIcon fontSize="large" />
          </Button>
          <Menu
            id="sort-menu"
            aria-labelledby="sort-menu-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setProducts([...products].sort((a, b) => a.price - b.price));
              }}
            >
              Price: Low to High
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setProducts([...products].sort((a, b) => b.price - a.price));
              }}
            >
              Price: High to Low
            </MenuItem>
          </Menu>
        </Box> */}
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={2}>
              <Card>
                <CardContent>
                  <Stack spacing={2}>
                    <Typography fontWeight={"bold"} variant="h6">
                      Sort By
                    </Typography>
                    <Link
                      fontSize={14}
                      sx={{
                        cursor: "pointer",
                        color:
                          selectedSort === "Price: Low to High"
                            ? "secondary.main"
                            : "primary.main",
                      }}
                      onClick={() => {
                        setSelectedSort("Price: Low to High");
                        setAnchorEl(null);
                        setProducts(
                          [...products].sort(
                            (a, b) =>
                              parseFloat(a.price_unit) -
                              parseFloat(b.price_unit)
                          )
                        );
                      }}
                    >
                      Low to High
                    </Link>
                    <Link
                      fontSize={14}
                      sx={{
                        cursor: "pointer",
                        color:
                          selectedSort === "Price: High to Low"
                            ? "secondary.main"
                            : "primary.main",
                      }}
                      onClick={() => {
                        setSelectedSort("Price: High to Low");
                        setAnchorEl(null);
                        setProducts(
                          [...products].sort(
                            (a, b) =>
                              parseFloat(b.price_unit) -
                              parseFloat(a.price_unit)
                          )
                        );
                      }}
                    >
                      High to Low
                    </Link>
                  </Stack>
                  <Stack
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Typography fontWeight={"bold"} variant="h6">
                      Categories
                    </Typography>
                    {subCategories
                      .filter(
                        (item) =>
                          item.parent_category === subCategory.parent_category
                      )
                      .map((item) => (
                        <Link
                          fontSize={14}
                          key={item.id}
                          sx={{
                            display: "block",
                            mt: 2,
                            cursor: "pointer",
                            color:
                              item.id === subCategory.id
                                ? "secondary.main"
                                : "primary.main",
                          }}
                          onClick={() => navigate(`/subcategory/${item.id}`)}
                        >
                          <Typography variant="p">{item.name}</Typography>
                        </Link>
                      ))}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <Grid container spacing={0}>
                {products.map((item) => (
                  <Grid item xs={6} md={4} lg={3}>
                    <ProductCard product={item} breadcrumb={subCategory?.name} />
                  </Grid>
                ))}
              </Grid>
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Box
                    component={"img"}
                    src={require("../../Assets/images/loader.gif")}
                    height={100}
                    width={300}
                    style={{
                      objectFit: "cover",
                    }}
                    sx={{
                      mt: 2,
                      mb: 5,
                    }}
                    alt={"loading"}
                  />
                </Box>
              )}

              {next && !loading && (
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Typography>
                    You've viewed {currentProducts} of {totalProducts}
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: 50, mt: 2, mb: 5 }}
                    onClick={loadMore}
                  >
                    Load More Products
                  </Button>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default SubCatProducts;
